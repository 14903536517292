export enum PaymentTxnRefundStatus {
    Initiated = 'Initiated',
    Pending = 'Pending',
    Failed = 'Failed',
    Completed = 'Completed',
}

export enum PaymentSDK {
    Paytm = 'paytm',
    Razorpay = 'razorpay',
    BillDesk = 'billdesk',
    Khalti = 'khalti',
    NepalPaymentSystem = 'nps',
}

export enum PaymentGatewayName {
    Paytm = 'paytm',
    Razorpay = 'razorpay',
    BillDesk = 'billdesk',
    Khalti = 'khalti',
    NPS = 'nps',
}

export enum CurrencyType {
    IndianRupee = 'INR',
    NepaliRupee = 'NPR',
}


// new payment apis /*

export enum PaymentPurpose {
    Charging = '10',
    Reservation = '20',
    PersonalWalletTopUp = '30',
    SubmitFlow = '100',
}

/**
 * @deprecated use `SubmitFlowPaymentReason` instead
 */
export enum SubmitFlowPaymentPurpose {
    SecurityDepositDocumentFee = 'Sec Dep/Doc Fee',
    DailyAmountDeposit = 'Daily Amount Deposit',
    DailyRental = 'Daily Rental',
}

/**
 * same as `WhatsappFlowTrackStepPaymentPurpose`
 */
export enum SubmitFlowPaymentReason {
    SecurityDepositDocumentFee = 'Sec Dep/Doc Fee',
    DailyAmountDeposit = 'Daily Amount Deposit',
    DailyRental = 'Daily Rental',
}

export enum PaymentGateway {
    Razorpay = '10',
    Paytm = '20',
    BillDesk = '30',
    Khalti = '40',
    NPS = '50',
}

export enum PaymentMedium {
    SDK = '10',
    Link = '20',
}

// new payment apis */